<template>
  <div class="border-all rounded">
    <div class="pt-4 px-4">
      <v-row class="my-0">
        <v-col cols="6" class="mb-3">
          <v-text-field
            type="text"
            label="Adı"
            filled
            dense
            v-model="filters.name"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="6" class="mb-3">
          <v-text-field
            type="text"
            label="Təsviri"
            filled
            dense
            v-model="filters.description"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="border-top py-3 px-4">
      <div class="d-flex justify-end">
        <v-btn @click="clear" class="mr-3" color="gray lighten-3" depressed>
          Təmizlə
        </v-btn>
        <v-btn @click="search" color="primary" depressed>
          Axtar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {
        name: "",
        description: "",
      },
    };
  },
  methods: {
    search() {
      this.$emit("filtersChanged", this.filters);
    },
    clear() {
      this.$emit("filtersChanged", {});
      this.filters = {
        name: "",
        description: "",
      };
    },
  },
};
</script>
